<template>
    <vue-perfect-scrollbar
        :settings="{suppressScrollX: true, wheelPropagation: false}"
        class="h-100 rtl-ps-none ps scroll"
        style="height: 100%;"
    >
        <div class="pa-5 relative">
            <v-app-bar
                :color="$vuetify.theme.dark ? 'dark' : 'grey lighten-4'"
                :dark="$vuetify.theme.dark"
                class="pt-1"
                fixed
                flat
            >
                <div class="d-flex justify-space-between align-center w-full">
                    <h6 class="ma-0">My Account</h6>
                    <slot name="userDrawerCloseButton"></slot>
                </div>
            </v-app-bar>
        </div>

        <div class="pa-5 mt-10">
            <div class="heading-label pa-0 mb-4">Profile</div>
            <div class="d-flex align-center mb-10">
                <v-avatar size="56" class="mr-2">
                    <img
                        width="50"
                        height="50"
                        src="@/assets/images/faces/1.jpg"
                        alt=""
                    />
                </v-avatar>
                <div>
                    <a href="#" class="link-alt">
                        <p class="font-weight-medium ma-0 ">{{loggedInUser.name}}</p>
                    </a>
                    <p class="body-2 text--disabled mb-2">
                        {{loggedInUser.email}}
                    </p>
                </div>
            </div>

            <div class="heading-label pa-0 mb-4">Organizations</div>
            <div class="mb-10">
                <div v-for="(item, index) in loggedInUser.organizations" :key="index" class="d-flex">
                    <div class="flex-grow-1">
                        <p class="font-weight-semi ma-0 ">{{ item.name }}</p>
                        <!-- <p class="text--disabled caption">
                            {{ item.subTitle }}
                        </p> -->
                    </div>
                </div>
            </div>

        </div>
    </vue-perfect-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'UserDrawer',
    props: {},
    data() {
        return {
            items: [
                {
                    letter: 'A',
                    title: 'Angular',
                    subTitle: 'Frontend framework',
                    progressbarText: '90',
                    progressvalue: 90,
                    progressColor: 'danger'
                },
            ]
        }
    },
    computed: {
        ...mapGetters(['loggedInUser'])
    },
    methods: {}
}
</script>

<style scoped></style>
